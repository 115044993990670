import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Button } from 'react-bootstrap'
import { sendGetRequest, sendPostRequest } from './api';
// import { statelist } from './statelist'
import { validationSchema } from './validation'
import { clientkey } from './clientkey'
import { ConfirmItem, FormItem, FormSelectItem, FormCheckboxConfirm } from './FormComponents'

const MainContent = (props) => {
  // const [ racelist, setRacelist ] = useState([]);
  // const [ sexlist, setSexlist ] = useState([]);
  const [ pagedata, setPagedata ] = useState({});
  const [ eventlist, setEventlist ] = useState([]);
  const [ slotlist, setSlotlist ] = useState([]);
  const [ typelist, setTypelist ] = useState([]);
  const [ confirmState, setConfirmState ] = useState(false);
  const [ pendingState, setPendingState ] = useState(false);
  const [ failState, setFailState ] = useState(false);
  const [ confirmationID, setConfirmationID ] = useState('');

  const submitData = (values) => {
    // console.log(new Date(values.birthdate).toISOString())
    setPendingState(true);
    sendPostRequest('update', {
      'clientkey': clientkey,
      'agency': props.agency,
      'appointmentid': values.slot,
      'type': props.type,
      'data': JSON.stringify({
        ...values,
        birthdate: new Date(values.birthdate).toISOString(),
        validation: 1,
        textconsent: values.textconsent ? 1 : 0
      })
    }).then((data) => {
      // console.dir(data);
      setConfirmState(true);
      setConfirmationID(data.id);
    }).catch((err) => {
      // console.log(err);
      setFailState(true);
    })
  }

  useEffect(() => {
    sendGetRequest('init', { 'clientkey': clientkey, 'agency': props.agency, 'type': props.type })
      .then((data) => {
        // setRacelist(data.race);
        // setSexlist(data.sex);
        setPagedata(data.page);
        setEventlist(data.events);
        setSlotlist(data.slots);
        setTypelist(data.types);
        console.dir(data.page);
  })
  }, [props.agency, props.type])

  return (
    <div className="content-div">
      <div className="header-div">
        <div className="image-div">
          {/* <img alt="" src={"/images/public-health-shield-half.png"} style={{display: "block", float: "left"}} />  */}
          <img alt="" src={pagedata.logo ? pagedata.logo : "/images/public-health-shield-half.png"} style={{display: "block", float: "left"}} /> 
        </div>
        <div className="title-div">
          <h2>{pagedata.title}</h2>
          <h4>{pagedata.agency}</h4>
        </div>
      </div>
      <br/>

      {failState ? 
        <p>An error has occurred. Please try again, or contact the health department at {pagedata.phone} to schedule your appointment.</p>
      : 
        <Formik
          initialValues={{ event: 'DEFAULT', slot: 'DEFAULT', validation: 0, lname: '', fname: '', email: '', phone: '', textconsent: false, type: 'DEFAULT' }}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty
            /* and other goodies */
          }) => (
            confirmState || pendingState ?
              confirmState ?
                <Form>
                  <ConfirmItem id="userConfirmMessage">Your appointment has been scheduled. To cancel or reschedule this appointment please call {pagedata.phone} and refer to your confirmation ID #{confirmationID}. Please print/screenshot this confirmation page for your personal records.</ConfirmItem>

                  <ConfirmItem id="userNameConfirm">Name: {values.fname} {values.lname}</ConfirmItem>
                  <ConfirmItem id="userEmailConfirm">Email Address: {values.email}</ConfirmItem>
                  <ConfirmItem id="userPhoneConfirm">Phone Number: {values.phone}</ConfirmItem>
                  <ConfirmItem id="userPhoneConfirm">Text Reminders: {values.textconsent ? 'Yes' : 'No'}</ConfirmItem>
                  <ConfirmItem id="userAppointmentType">Appointment Type: {values.type}</ConfirmItem>
                  {/* <ConfirmItem id="userAddress1Confirm">Address 1: {values.address1}</ConfirmItem> */}
                  {/* <ConfirmItem id="userAddress2Confirm">Address 2: {values.address2}</ConfirmItem> */}
                  {/* <ConfirmItem id="userCityConfirm">City: {values.city}</ConfirmItem> */}
                  {/* <ConfirmItem id="userStateConfirm">State: {values.state}</ConfirmItem> */}
                  {/* <ConfirmItem id="userZipConfirm">Zip Code: {values.zip}</ConfirmItem> */}
                  {/* {sexlist.filter(item => values.sex === item.oid).map(item => <ConfirmItem id="userSexConfirm">Birth Sex: {item.description}</ConfirmItem> )} */}
                  {/* {racelist.filter(item => values.race === item.oid).map(item => <ConfirmItem id="userRaceConfirm">Race: {item.description}</ConfirmItem> )} */}
                  <ConfirmItem id="userBirthDateConfirm">Birth Date: {values.birthdate}</ConfirmItem>
                  {eventlist.filter(item => values.event === item.oid).map(item => <ConfirmItem id="userEventConfirm">Event: {item.description}</ConfirmItem> )}
                  {slotlist.filter(item => values.slot === item.ref).map(item => <ConfirmItem id="userSlotConfirm">Timeslot: {item.description}</ConfirmItem> )}
                </Form>
              :
                <Form>
                  <ConfirmItem id="userPendingMessage">Your Request is being processed. Please do not close this page.</ConfirmItem>
                </Form>
            :
              <Form>
                { pagedata.description ? <ConfirmItem id="userAgencyMessage">{pagedata.description}</ConfirmItem> : null}
                <ConfirmItem id="userScheduleMessage">Please complete the following information to schedule your appointment.</ConfirmItem>
                <FormItem id="userFirstName" name="fname" label="First Name" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem>
                <FormItem id="userLastName" name="lname" label="Last Name" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem>
                <FormItem id="userEmail" name="email" label="Email Address" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem>
                <FormItem id="userPhone" name="phone" label="Phone Number" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}>
                  <Form.Check name="textconsent" onChange={handleChange} onBlur={handleBlur} value={values.textconsent} label="Do you want to receive text reminders?" />
                </FormItem>
                {/* <FormItem id="userAddress1" name="address1" label="Address 1" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem> */}
                {/* <FormItem id="userAddress2" name="address2" label="Address 2" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem> */}
                {/* <FormItem id="userCity" name="city" label="City" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem> */}
                {/* <FormSelectItem id="userState" name="state" label="State" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}>
                  {statelist.map((item, key) =>
                    <option key={key} value={item.abbreviation}>{item.name}</option>
                  )}
                </FormSelectItem> */}
                {/* <FormItem id="userZip" name="zip" label="Zip Code" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem> */}
                {/* <FormSelectItem id="userSex" name="sex" label="Birth Sex" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}>
                  <option disabled key={'DEFAULT'} value={'DEFAULT'}>Please select sex...</option>
                  {sexlist.map((item, key) =>
                    <option key={key} value={item.oid}>{item.description}</option>
                  )}
                </FormSelectItem> */}
                {/* <FormSelectItem id="userRace" name="race" label="Race" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}>
                  <option disabled key={'DEFAULT'} value={'DEFAULT'}>Please select race...</option>
                  {racelist.map((item, key) =>
                    <option key={key} value={item.oid}>{item.description}</option>
                  )}
                </FormSelectItem> */}
                <FormItem id="userBirthDate" name="birthdate" label="Birth Date" placeholder="MM/DD/YYYY" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem>

                { !values.validation 
                  ? <FormCheckboxConfirm id="userValidation" name="validation" label="I have confirmed that the information entered above is correct" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched} />
                  : <FormSelectItem id="userEventType" name="type" label="Select Appointment Type" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}>
                      <option disabled key={'DEFAULT'} value={'DEFAULT'}>Please select appointment type...</option>
                      {typelist.map(item => <option key={item} value={item}>{item}</option>)}
                    </FormSelectItem>
                }

                { values.type !== "DEFAULT" ?
                  <FormSelectItem id="userEvent" name="event" label="Select Appointment Date" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}>
                    <option disabled key={'DEFAULT'} value={'DEFAULT'}>Please select appointment...</option>
                    {eventlist.filter(item => values.type === item.type).map((item, key) => <option key={key} value={item.oid}>{item.description}</option> )}
                  </FormSelectItem>
                : null}

                { values.event !== 'DEFAULT' ?
                  <FormSelectItem id="userSlot" name="slot" label="Select Timeslot" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}>
                    <option disabled key={'DEFAULT'} value={'DEFAULT'}>Please select timeslot...</option>
                    {slotlist.filter(item => values.event === item.event).map((item, key) => <option key={key} value={item.ref}>{item.description}</option> )}
                  </FormSelectItem>
                : null }
                
                { values.validation
                  ? <Button variant="primary" disabled={!(isValid && dirty)} onClick={() => submitData(values)}>Submit</Button>
                  : null
                }

              </Form>          
          )}
        </Formik>
      }
    </div>
  );
}

export { MainContent };
