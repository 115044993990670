import React, { Component } from 'react';
import './App.css';
import { sendPostRequest } from './components/api';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { MainContent } from './components/MainContent'
import { clientkey } from './components/clientkey'

class MyReCaptcha extends Component {
  verifyCallback = (recaptchaToken) => {
    sendPostRequest('verify', {
      'clientkey': clientkey,
      'response': recaptchaToken
    })
  }

  updateToken = () => {
    this.recaptcha.execute();
  }

  render() {
    return (
      <ReCaptcha 
        ref={ref => this.recaptcha = ref}
        sitekey="6LcKqz4dAAAAAOuMyJ7DEvCPWyA8XL5YOOhuOeoq"
        action='verify_activity'
        verifyCallback={this.verifyCallback}
      />
    );
  }
}

const Block404 = () => {
  return (
    <div className="content-div">
      <p>Something went wrong! This page does not exist yet.</p>
    </div>
  )
}

class App extends Component {

  componentDidMount() {
    loadReCaptcha('6LcKqz4dAAAAAOuMyJ7DEvCPWyA8XL5YOOhuOeoq');
  }

  render() {
    return (
      <>
        <Router>
          <Routes>
            <Route path="/cecil/vitalrecords" element={<MainContent type="records" agency="CECIL" />} />
            <Route path="/wicomico/vitalrecords" element={<MainContent type="records" agency="WICOMICO" />} />
            <Route path="/mag/vitalrecords" element={<MainContent type="records" agency="MAG" />} />
            <Route path="/cecil/clinic" element={<MainContent type="clinic" agency="CECIL" />} />
            <Route path="/garrett/clinic" element={<MainContent type="clinic" agency="GARRETT" />} />
            <Route path="/dorchester/clinic" element={<MainContent type="clinic" agency="DORCHESTER" />} />
            <Route path="/talbot/clinic" element={<MainContent type="clinic" agency="TALBOT" />} />
            <Route path="/queenannes/clinic" element={<MainContent type="clinic" agency="QUEENANNES" />} />
            <Route path="/charles/clinic" element={<MainContent type="clinic" agency="CHARLES" />} />
            <Route path="/mag/clinic" element={<MainContent type="clinic" agency="MAG" />} />
            <Route path="/" element={<Block404 />} />
          </Routes>
        </Router>
        <MyReCaptcha />
      </>
    );
  }

}

export default App;
